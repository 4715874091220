angular
    .module('annexaApp')
    .component('annexaBoxObjectThirdsDocument',{
        templateUrl: './components/thirds/annexa-box-object-third-document/annexa-box-object-third-document.html',
        controller:['$rootScope', '$filter', 'Language', 'GlobalDataFactory', 'HelperService', 'CommonService', '$scope', '$state', 'NotificationFactory', 'AnnexaFormlyFactory', 'TerritoryFactory', 'SecFactory', 'AnnexaPermissionsFactory', '$state', 'globalModals', 'TramNewFactory', 'DialogsFactory', 'ThirdFactory', '$http', 'DccumentsFactory', 
		function($rootScope, $filter, Language, GlobalDataFactory, HelperService, CommonService, $scope, $state, NotificationFactory, AnnexaFormlyFactory, TerritoryFactory, SecFactory, AnnexaPermissionsFactory, $state, globalModals, TramNewFactory, DialogsFactory, ThirdFactory, $http, DccumentsFactory) {
        	//Està basat en annexaBoxObjectDossierTransactionDocuments
        	var vm = this;
       		vm.languageColumn = undefined;
       		vm.isUpdate = false;
       		vm.isLoaded = false;
       		vm.documentTableDefinition = undefined;
       		vm.archiveClassification = undefined;
       		vm.third.documents = [];
			
			var generateDocumentBox= function(){
				if(!vm.documentTableDefinition){
					vm.documentTableDefinition = {};
				}
				
				vm.openDocument = function(documentId){
		            var windowReference = window.open();
		            DccumentsFactory.getDocContent(documentId).then(function (data) {
		                windowReference.location = data;
		            }).catch(function (error) {
		                windowReference.close();
		            });
		        }

				vm.deleteDocument = function(documentId) {
		        	var titleDeleteMessage = '';
		        	var confirmDeleteMessage = '';
		        	
		        	titleDeleteMessage = 'global.literals.delete';
		        	confirmDeleteMessage = 'global.literals.deleteDocumentBox';
		        	
		        	DialogsFactory.confirm(titleDeleteMessage, confirmDeleteMessage)
		                .then(function (data) {
			                    if(vm.isUpdate) {
									DccumentsFactory.deleteDocumentThird(documentId, vm.third.id).then(function (data) {
										if(vm.documentTableDefinition && vm.documentTableDefinition.reloadInternalData){
											vm.documentTableDefinition.reloadInternalData(true, true);	
										}
//										vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.calculateCounters('dossierDossiers');
			                        }).catch(function (error) {
//			                        	vm.dossierTransactionComponent.dossierTransactionsComponent.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossierAlerts.length = 0;
//			            				vm.dossierTransactionComponent.dossierTransactionsComponent.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossierAlerts.push({msg:$filter('translate')('global.errors.unknownUpdate')});
			                        });
			                    }
		                }).catch(function (data) {
		                    //Empty
		            });
		        }
	            
				var actionsDocumentRender = function (data, type, full, meta) {
					var content = '';
					if(vm.isUpdate){
						content += '<button type="button"  class="md-btn p-a-0 md-flat md-btn__star grey-pencil" ng-click="$ctrl.deleteDocument('+full.id+')" annexa-permission annexa-permission-only="[\'edit_third\']">';
		                content += '<i class="fa fa-remove" title="{{ \'global.literals.remove\' | translate }}" aria-hidden="true"></i>';
		                content += '<span class="sr-only">{{ \'global.literals.remove\' | translate}}</span>';
		                content += '</button>';
					}
	                return content;
	            }
				vm.addDocumentSave = function(newDoc){
					if(newDoc && newDoc.length > 0){
						var thirdDocuments = [];
						var newDocAux = angular.copy(newDoc);
						_.forEach(newDocAux, function(doc){
							if(doc.document && doc.document.id){
								vm.third.documents.push({id: doc.document.id});
								doc.document.id = {id: doc.document.id};
							}
						});
						ThirdFactory.newThirdDocuments(vm.third).then(function (data) {
							setTimeout(function() {
								if(vm.documentTableDefinition && vm.documentTableDefinition.reloadInternalData){
									vm.documentTableDefinition.reloadInternalData(true, true);
								}
							}, 500);
//								vm.calculateCounters();
		                }).catch(function (error) {
//			                	vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossierAlerts.length = 0;
//			    				if(error && error.data && error.data.message ==  'error-requestCompleteDossier-is-in-restictive-transaction not found'){
//			                     	vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossierAlerts.push({msg:$filter('translate')('global.tram.errors.inRestrictiveTransaction')});
//			                    }else{
//									vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossierAlerts.push({msg:$filter('translate')('global.errors.unknownUpdate')});
//								}
		                });
		            }
				}
					
				var actionsTitle = function () {
    	            return '<div class="column-actions">' + $filter('translate')('global.literals.actions') + '</div>';
	            }

				var columns = [
					{ id: 'id', width: '250px', column: new UIRouterStateColumn($filter, 'global.literals.code', 'annexa.doc.documents.view({code: \'[data]\'})', undefined, 'var code = full.id; if(full.code) { code = full.code;} code;', true,undefined,(($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.close_and_archive && $rootScope.app.configuration.close_and_archive.showArchivedDocument)?'$ctrl.openDocument([data])':undefined)) },
	                { id: 'name', width: '250px', column: new UIRouterStateColumnDataLink($filter, 'global.literals.title', 'annexa.doc.documents.view({code: \'[data]\'})', 'full.id', true,(($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.close_and_archive && $rootScope.app.configuration.close_and_archive.showArchivedDocument)?'$ctrl.openDocument([data])':undefined)) },
	                { id: 'actions', columnName: 'id', width: '120px', className: 'text-center', title: actionsTitle(), render: actionsDocumentRender, sortable: false }
                ];

				var tableOrderProperties = {sortName: 'code', sort: [[0,'desc']]};
		        vm.documentTableDefinition.id = 'tableTDocuments';
				vm.documentTableDefinition.origin = 'doc';
		        vm.documentTableDefinition.objectType = 'Document';
				vm.documentTableDefinition.objectIdToSelectOrigin ='thirds';
				vm.documentTableDefinition.objectIdToSelectType = 'Third';
		        vm.documentTableDefinition.sortName = tableOrderProperties.sortName;
		        vm.documentTableDefinition.sort = tableOrderProperties.sort;
				vm.documentTableDefinition.columns = columns;
		        vm.documentTableDefinition.containerScope = $scope;
				vm.documentTableDefinition.useTableObjects = true;
				vm.documentTableDefinition.callOrigin = 'thirds';
				vm.documentTableDefinition.objectIdToSelect = angular.fromJson({id:vm.third.id});
				vm.documentTableDefinition.addToAdditionalFilter = {"documentBoxNormalSearch": true}
				vm.documentTableDefinition.objectIdToAdded = vm.third.id;
				
				vm.documentBox = {
				    boxTitle: 'global.thirds.literals.thirdDocs',
					permissions: { view: 'edit_third', new: 'edit_third'},
					new: {multiple:{scanner:true}, showDossierOptions: false},
					tableDefinition: vm.documentTableDefinition,
                    isEdit: true,
                    search: { placeholder: 'global.literals.placeholser_search_documents', advanced: true, saveSearch: vm.addDocumentSave},
                    config: {third: vm.third, module:"THIRD", documentTitle: undefined},
                    origin: 'thirds',
					alerts: {},
					legend: undefined,
					closeBox: {value:true},
					numberBox: undefined
                }
				
			}

			this.$onInit = function() {
				vm.languageColumn = Language.getActiveColumn();
				vm.isUpdate = AnnexaPermissionsFactory.haveSinglePermissionDual('edit_third', undefined);
				vm.dossierBox = {};
        		try{
					generateDocumentBox();
					$scope.$on('annexaBoxDossierDossiersReloadTable', function(event, args) {
			            if(vm.documentTableDefinition && vm.documentTableDefinition.reloadInternalData) {
							vm.documentTableDefinition.reloadInternalData(true, true);	
						}
//						vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.calculateCounters('dossierDossiers');
			        });
				}catch(error){
					 console.error(error);
				}
			}
        }],
        bindings: {
        	third: '='
        }
    });